





import { Component, Vue } from "vue-property-decorator";

import { PermissionEntity } from "@/iam/models/PermissionModel";

@Component
export default class IamView extends Vue {
  isNamesLoading = false;

  async created() {
    this.isNamesLoading = true;
    // TODO: NGAC temporary only 2 entities
    await Promise.all([
      this.$store.dispatch("getEntityNames", [
        PermissionEntity.APPLICATION,
        PermissionEntity.APP_SECTION,
        PermissionEntity.AD_NETWORK,
      ]),
      this.$store.dispatch("getAggregatorsNames", [
        PermissionEntity.APPLICATIONS,
        PermissionEntity.APP_SECTIONS,
        PermissionEntity.AD_NETWORKS,
      ]),
    ]).finally(() => {
      this.isNamesLoading = false;
    });
  }
}
